.filter-view-block {
    margin: 20px 0;
}

.filter-view p {
    margin: 0 10px;
    display: inline-block;
}

.filter-view-select {
    min-width: 150px;
}

.filter-view-select-long {
    min-width: 300px;
}