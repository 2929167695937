.station-filter {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-control {
    margin: 24px 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}