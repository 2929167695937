.map-container {
    margin-top: -56px;
    /* height of the header */
    height: 100vh;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 402;
    /* higher than the map which is 400 */
    transition: visibility 0s 1s, opacity 1s linear;
}

.leaflet-control-zoom {
    display: none;
}

.menu-button {
    transition: left .2s ease;
    left: 10px;
    top: 62px;
    z-index: 402;
}

.show-all-lines {
    left: 60px;
    top: 66px;
    z-index: 402;
    position: absolute;
}

header {
    position: sticky;
    width: 100%;
    z-index: 9999;
    box-shadow: 0px 0 10px 1px #00000080;
    display: flex;
    justify-content: space-between;
    padding: 0 4px !important;
}

.logo {
    width: 48px;
    height: 48px;
}

.header-content {
    display: inline-flex;
    align-items: center;
    height: inherit;
}

.toggle-button {
    align-self: center;
    padding-right: 1rem;
}

.title {
    margin: 0 0 0 8px;
}

.close-button {
    position: absolute;
    right: 4px;
    top: 60px;
}

.contributors {
    background-color: #ffffff;
    margin-top: 20px;
}
