h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

body {
  overflow: hidden;
}

.ant-layout-content {
    height: calc(100vh - 56px); /* 56 is the app bar height */
}