.tl-container {
    margin: 10px 0;
}

.tl-container > .ant-card-body {
    padding-bottom: 10px;
}

p {
    margin: 0;
}

.second-row {
    margin: 20px 0 25px;
}


.ant-steps-item {
    padding-bottom: 10px;
}
.ant-steps-item:last-child .ant-steps-item-content {
    padding-left: 6px;
    width: 180px;
}

.ant-steps-item:not(:last-child) .ant-steps-item-content {
    width: 240px;
    padding-right: 60px;
}

.ant-steps-horizontal {
    overflow-x: scroll;
    padding-top: 1px; /* without this the dots are cut off */
}

.ant-steps-item-tail {
    margin-inline: 90px 0 !important;
}

.ant-steps-item-icon {
    margin-inline-start: 86px !important;
}

.ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45) !important;
}

.sk-btn {
    margin-top: 4px;
}

.line-info {
    display: inline-block;
    margin-bottom: 20px;
}